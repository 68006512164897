.card:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 4px 8px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 2px 1px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
  border-radius: 8px;
}
.media:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 4px 8px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 2px 1px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
  border-radius: 8px;
  cursor: pointer;
}

.article-header {
  color: $secondary;
  border-radius: 8px;
}
.user-nav {
  background: var(--bs-btn-bg);
  border-radius: 50%;
  height: 32px;
  width: 32px;
  position: relative;
  color: white;
  justify-content: center;
  display: flex;
  & > a {
    font-size: 1rem;
    color: white;
    padding: 0;
    &:hover {
      color: white;
    }
    &::after {
      content: none;
    }
  }
}
.nav_index {
  position: relative;
  z-index: 2;
}

.motion-index {
  z-index: 1;
  position: relative;
}
@media only screen and (min-width: 600px) {
  .nav_index {
    height: 50px;
  }
}
.form-control:focus {
  box-shadow: 0 0.25rem #c3001f25;
}
.breadcrumb-item {
  & a {
    text-decoration: none;
  }
}
.status-icon {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: inline-block;
}
.default-article {
  min-height: 200px;
}
.media:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 4px 8px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 2px 1px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
  border-radius: 8px;
  cursor: pointer;
}
.article-header {
  color: $secondary;
  // background-color: #fbfbfb;
  border-radius: 8px;
}
