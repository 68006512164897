.nav-link {
  padding: 10px 20px 20px;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 700;
  transition: 0.3s ease;
  color: $secondary;

  &:hover,
  &:active,
  &:focus,
  &:target,
  &.active {
    color: $primary;
  }
}
