.langswitcher {
  justify-content: flex-end;
  display: flex;
  &.drawer {
    height: 100%;
    align-items: center;
    a {
      color: white;
    }
  }
  & > .langswitcher__list {
    list-style: none;
    display: flex;
    & > .langswitcher__element {
      padding: 0.3rem;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 1rem;
      & > .langswitcher__link {
        text-decoration: none;
        color: black;
      }
    }
  }
}
