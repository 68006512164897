:root {
  --bs-breadcrumb-divider: ">";
  $breadcrumb-active-color: $primary;
}
.breadcrumb-wrapper {
  height: 3rem;
  background-color: $breadcrumb-background;
  display: flex;
  position: relative;
  z-index: 2;
}
.breadcrumb {
  color: $breadcrumb-color;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;
}
.breadcrumb-item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;
  & > a {
    color: $breadcrumb-color;
  }
}
.container {
  display: flex;
}
