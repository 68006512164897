@font-face {
  font-family: "Source Sans Pro", sans-serif;
  src: "/fonts/Source_Sans_Pro/SourceSansPro-Bold.ttf" format("truetype"),
    "/fonts/Source_Sans_Pro/SourceSansPro-Bold.ttf" format("truetype");
  font-display: swap;
}
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Source Sans Pro", Helvetica, sans-serif;
}

:root {
  --bs-body-font-family: "Source Sans Pro", Helvetica, sans-serif;
  --bs-font-sans-serif: "Source Sans Pro", Helvetica, sans-serif;
}
